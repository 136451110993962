<template>
  <div class="bigbox">
    <div>
      <van-nav-bar title="我的卡券" left-arrow @click-left="$router.back()" />
    </div>
    <van-tabs v-model="active">
      <van-tab style="background:#eee;height:100%" title="全部">
        <div class="carbox" v-for="(v,i) in FileList" :key="i">
          <div class="imgbox">
            <div>
              <span style="font-size:35px;color:#EB4541">8.5</span>折
            </div>
            <div>满25可使用</div>
          </div>
          <div style="line-height:30px;margin-left:20px;width:70%;box-sizing: border-box;">
            <div style="font-weight:bold;font-size:14px">{{v.title}}</div>
            <div style="color:#888;width:150px">{{v.tips}}></div>
            <div>
              <button class="btn">立即使用</button>
              <button v-if="ishow" style="background:#ccc;color:#fff;border:0" class="btn">已兑换</button>
            </div>
          </div>
        </div>
      </van-tab>
      <van-tab title="未使用"></van-tab>
      <van-tab title="已使用"></van-tab>
    </van-tabs>
  </div>
</template>

<script>
import { Toast } from 'vant'
export default {
  name: 'TaxiH5OrderDetails',
  components: {},

  data() {
    return {
      active: 0,
      ishow: false,
      FileList: [
        { title: '出行套餐7折券x5张', tips: '有效期至2021年10月23日' },
        { title: '出行套餐7折券x1张', tips: '有效期至2021年10月23日' },
      ],
      FileList2: [
        { name: '打车8折券', des: '打车8折券' },
        { name: '打车8折券', des: '打车8折券' },
        { name: '打车8折券', des: '打车8折券' },
      ],
      FileList3: [
        {
          title: '1100元礼品券',
          price: '38',
          tips: '输入兑换码即可获得100元账户余额',
        },
        {
          title: '200元礼品券',
          price: '3',
          tips: '输入兑换码即可获得100元账户余额',
        },
        {
          title: '100元礼品券',
          price: '41',
          tips: '输入兑换码即可获得100元账户余额',
        },
        {
          title: '500元礼品券',
          price: '22',
          tips: '输入兑换码即可获得100元账户余额',
        },
      ],
    }
  },

  mounted() {},

  methods: {
    //返回
    onClickLeft() {
      Toast('返回')
    },
  },
}
</script>

<style lang="scss" scoped>
.bigbox {
  width: 100%;
  height: 100vh;
  background: #eee;
  .carbox {
    width: 90%;
    display: flex;
    height: 100px;
    border-radius: 15px;
    // border:solid 1px #eee;
    box-shadow: #ccc 0 0 10px;
    margin: 10px 5%;
    box-sizing: border-box;
    background: #fff;
    .imgbox {
      background: #fdf2f2;
      width: 30%;
      text-align: center;
      padding-top: 20px;
      color: #666666;
      border-radius: 15px 0 0 15px;
    }

    .btn {
      background: #fff;
      border: solid 1px #eb4541;
      color: #eb4541;
      width: 80px;
      height: 25px;
      margin-top: 5px;
      line-height: 25px;
      border-radius: 15px;
    }
  }
  .carbox2 {
    background: #fcd8cf;
    // height: 300px;
    .topcar {
      display: flex;
      justify-content: space-between;
      margin-left: 5%;
      width: 90%;
      height: 100px;
      background: #fff;
      border-radius: 16pt;
      line-height: 30px;
      box-sizing: border-box;
      align-items: center;
      margin-top: 20px;
      img {
        width: 100pt;
        height: 60pt;
      }
      .btnbox {
        .btn {
          border: #eb4541 1px solid;
          width: 50px;
          height: 50px;
          color: #eb4541;
          border-radius: 16pt;
          background: #fff;
          margin-right: 20px;
        }
      }
    }
  }
  .goodbox {
    width: 90%;
    height: 300px;

    margin: 0 5%;
    border-radius: 15px;
    margin-top: 10px;
    .back {
      width: 90%;
      height: 100px;
      margin: 10px 5%;
      background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng52559c42f93886e3afd9f54b916790890942a16a13c6d4e67617f9efe1396a03)
        no-repeat;
      display: flex;
      justify-content: space-between;
    }
  }
  .footbtnbox {
    width: 80%;
    height: 50px;
    background: #eb4541;
    color: #fff;
    border-radius: 15px;
    border: 0;
    margin-left: 10%;
  }
}
</style>